@import "../../../../vars";

$logo-wrapper-height: 80px;
$left-panel-width: 340px;

.wrapper_left_panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $left-panel-width;
  min-width: $left-panel-width;
  background: var(--color-white);
  transition: margin-left 150ms ease-in, width 150ms ease-in;
  // border-right: 1px solid var(--color-gray-5);
  box-shadow: 0 0 15px $color-shadow-2;
  // box-shadow: $card-shadow;
  z-index: 100;
  margin-left: 0;

  .content_left_panel {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 10;
    // border-right: 1px solid var(--color-gray-5);

    .wrapper_left_menu {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      height: 100%;
      position: absolute;

      > div {
        width: 100%;
      }

      .left_menu {
        padding-top: 10px;
      }
    }
  }

  .header_close_button {
    transition: right 150ms ease-in;
  }

  &[data-expanded="false"] {
    margin-left: -$left-panel-width;

    .header_close_button {
      font-size: $icon-size-lg;
      right: -34px;
    }
  }

  .left_bottom_menu {
    align-self: flex-end;
  }
}

.footer_left_menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 90px;
  min-height: 90px;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-gray-5);
  // border-right: 1px solid var(--color-gray-5);
  position: relative;
  overflow: hidden;

  .wrapper_current_user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 25px;
    width: 100%;

    .current_user_info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 95%;

      &:hover .current_user_name {
        color: var(--color-gray-8);
      }
    }

    .current_user_icon {
      margin-right: 10px;
    }

    .current_user_name {
      font-size: 17px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-gray-9);
    }
  }
}

.header_logo {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 100%;
  height: $logo-wrapper-height;
  min-height: $logo-wrapper-height;
  border-bottom: 1px solid var(--color-gray-4);
  z-index: 20;
  background-color: var(--color-white);
  // border-right: 1px solid var(--color-gray-5);

  .header_image_logo {
    position: relative;
    height: 35px;
    left: 26px;

    img {
      max-height: 100%;
    }
  }

  .header_close_button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 2px solid var(--color-gray-4);
    // border: 1px solid $color-shadow-1;
    // box-shadow: 3px 3px 7px $color-shadow-0;
    box-sizing: border-box;
    border-radius: 6px;
    right: -20px;
    cursor: pointer;
    z-index: 10;
    background: var(--color-white);
    font-size: $icon-size-lg;
    color: var(--color-gray-7);

    &:hover {
      background: var(--color-gray-3);
      color: var(--color-gray-8);
    }
  }
}

.always_closed.wrapper_left_panel {
  margin-left: -$left-panel-width;

  position: absolute;
  height: 100vh;
  z-index: 1000;

  .header_close_button {
    right: -34px;
  }

  &[data-expanded="false"] {
    margin-left: -$left-panel-width;

    .header_close_button {
      font-size: $icon-size-lg;
      right: -34px;
    }
  }

  &[data-expanded="true"] {
    margin-left: 0;

    .header_close_button {
      right: -20px;
    }
  }
}

@media (max-width: $screen-md-max) {
  .wrapper_left_panel {
    margin-left: -$left-panel-width;

    position: absolute;
    height: 100vh;
    z-index: 10;

    .header_close_button {
      right: -34px;
    }

    &[data-expanded="true"] {
      margin-left: 0;

      .header_close_button {
        right: -20px;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .wrapper_left_panel, .always_closed.wrapper_left_panel {
    transition: height 200ms ease-in;
    overflow: hidden;
    margin-left: 0 !important;

    &[data-expanded="false"] {
      width: 100%;
      min-width: 100%;
      height: $logo-wrapper-height;

      .header_close_button {
        right: 20px;
      }
    }

    &[data-expanded="true"] {
      width: 100%;
      min-width: 100%;
      height: 100%;

      .header_close_button {
        right: 20px;
      }
    }

    .left_bottom_menu {
      // position: static;
    }
  }
}
